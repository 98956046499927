<template>
	<v-card class="report-card pt-8 pb-3 px-5" :style="cardBorder">
		<v-card-title class="center-text text-h5">
			<div class="report-text">
				Hi friend! Please log in to submit a report.
			</div>
		</v-card-title>
		<v-card-actions class="mb-3">
			<v-btn color="primary" @click="login"> Log in </v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
export default {
	name: 'ReportCardLogin',
	data: () => ({
		redirect: process.env.VUE_APP_BACKEND + '/api/auth/discord',
	}),
	computed: {
		cardBorder() {
			return 'border: 3px solid var(--v-primary-base);';
		},
	},
	methods: {
		login() {
			this.$store.dispatch('setPreLoginUrl', this.$route.path);
			window.location.href = this.redirect;
		},
	},
};
</script>

<style scoped>
.report-card {
	display: flex;
	align-items: center;
	flex-direction: column;
	transition: all 0.5s ease;
}

.report-text {
	display: inline-block;
	word-break: break-word;
	text-align: center;
	width: 100%;
}

.center-text {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
}
</style>
