<template>
	<div>
		<ReportCardLogin v-if="!loggedIn" />
		<ReportCardForm v-else />
	</div>
</template>

<script>
import ReportCardLogin from '@/views/report/components/ReportCardLogin';
import ReportCardForm from '@/views/report/components/ReportCardForm';

export default {
	name: 'ReportCard',
	components: { ReportCardLogin, ReportCardForm },
	computed: {
		user() {
			return this.$store.getters.user;
		},
		loggedIn() {
			return !!this.user?.id;
		},
	},
};
</script>

<style scoped></style>
