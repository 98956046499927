<template>
	<v-card class="report-card pt-8 pb-3 px-5" :style="cardBorder">
		<v-card-text class="card-text pb-0">
			<div class="avatar-border" :style="cardBorder">
				<v-avatar size="63">
					<v-img :src="avatarUrl" @error="avatarError" alt="avatar" />
				</v-avatar>
			</div>
		</v-card-text>
		<span v-if="!done">
			<v-card-title class="center-text welcome-text text-h5 pb-0">
				How can I help you {{ username }}?
			</v-card-title>
			<v-card-text class="center-text text-body-2 pb-0">
				Please fill out the form below to create a report.
			</v-card-text>
			<v-card-text class="center-text text-body-2 pt-0">
				<a @click="login">Not you?</a>
			</v-card-text>
			<ReportCardFormInfo @done="done = true" />
		</span>
		<span v-else>
			<v-card-title class="center-text">
				Thank you for the report {{ username }}!
			</v-card-title>
			<v-card-text class="center-text">
				A moderator will review this soon!
			</v-card-text>
		</span>
	</v-card>
</template>

<script>
import ReportCardFormInfo from '@/views/report/components/ReportCardFormInfo';

export default {
	name: 'ReportCardForm',
	components: { ReportCardFormInfo },
	data: () => ({
		redirect: process.env.VUE_APP_BACKEND + '/api/auth/discord',
		avatarGifError: false,
		avatarPngError: false,
		done: false,
	}),
	computed: {
		user() {
			return this.$store.getters.user;
		},
		username() {
			return this.user.username + '#' + this.user.discriminator;
		},
		avatarUrl() {
			if (!this.user.avatar) {
				return `https://cdn.discordapp.com/embed/avatars/${
					parseInt(this.user.discriminator) % 5
				}.png`;
			} else if (!this.avatarGifError) {
				return this.user.avatarUrlGif;
			} else if (!this.avatarPngError) {
				return this.user.avatarUrlPng;
			}
			return null;
		},
		cardBorder() {
			return this.done
				? 'border: 3px solid var(--v-success-base);'
				: 'border: 3px solid var(--v-primary-base);';
		},
	},
	methods: {
		avatarError() {
			if (!this.avatarGifError) {
				this.avatarGifError = true;
			} else if (!this.avatarPngError) {
				this.avatarPngError = true;
			}
		},
		login() {
			this.$store.dispatch('setPreLoginUrl', this.$route.path);
			window.location.href = this.redirect;
		},
	},
};
</script>

<style scoped>
.report-card {
	margin: 100px 0px;
	display: flex;
	align-items: center;
	flex-direction: column;
	transition: all 0.5s ease;
}

.avatar-border {
	display: flex;
	align-items: center;
	border-radius: 50%;
	width: 69px;
	height: 69px;
	background: var(--v-background-base);
	transition: all 0.5s ease;
}

.card-text {
	position: absolute;
	top: -50px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
}

.welcome-text {
	display: inline-block;
	word-break: break-word;
	text-align: center;
	width: 100%;
}

.center-text {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
}
</style>
